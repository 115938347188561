<template>
    <div class="prb-filters">
        <div class="prb-filters-title">Filters</div>
        <div class="prb-filters-body" v-loading="loading">
            <div v-for="(row, index) in filters" class="prb-filters-row">
                <div class="prb-f-del_btn">
                    <el-popconfirm
                        confirmButtonText='OK'
                        cancelButtonText='Cancel'
                        hideIcon
                        title="Are you sure to delete this?"
                        v-if="!row.field_data.obligatory"
                        @confirm="filters.splice(index, 1);">
                            <el-button slot="reference"
                                type="text"
                                class="form-delete-btn">
                                <i class="el-icon-circle-close"></i>
                            </el-button>
                    </el-popconfirm>
                </div>
                <div class="prb-f-rule">
                    <el-select
                        v-model="row.rule"
                        size="small"
                        :disabled="row.field_data.obligatory || !index ||  filters[index - 1].field_data.obligatory">
                        <el-option label="AND" value="AND"></el-option>
                        <el-option label="OR" value="OR"></el-option>
                    </el-select>
                </div>
                <div class="prb-f-field_name">
                    {{row.field_data.title}}
                </div>
                <div class="prb-f-logic">
                    <el-select
                        v-model="row.logics"
                        size="small"
                        @change="change_row_logics(row)">
                        <el-option
                            v-for="item in row.field_data.operands"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </div>
                <div class="prb-f-value">
                    <div v-if="row.logics">
                        <div v-if="value_type(row.field_data, row.logics) === 'report_date_input'">
                            <DatePicker v-model="row.value" size="small" @dt-text="set_date_text(row, $event)" />
                            <div class="prb-f-date_text" v-if="row.date_text">{{row.date_text}}</div>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'select_input'">
                            <el-select
                                v-model="row.value"
                                size="small"
                                @change="change_row_value(row)">
                                <el-option
                                    v-for="item in row.field_data.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'select_multi_input'">
                            <el-select
                                v-model="row.value_list"
                                size="small"
                                multiple>
                                <el-option
                                    v-for="item in row.field_data.options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'date_input'">
                            <el-date-picker
                                v-model="row.value"
                                size="small"
                                type="date"
                                value-format="yyyy-MM-dd"
                                :clearable="false" />
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'range_date_input'">
                            <el-date-picker
                                v-model="row.value_list"
                                type="daterange"
                                size="small"
                                value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'input'">
                            <el-input
                                v-model="row.value"
                                size="small">
                            </el-input>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'multi_input'">
                            <el-select
                                v-model="row.value_list"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                no-data-text="">
                            </el-select>
                        </div>
                        <div v-else-if="value_type(row.field_data, row.logics) === 'range_input'"  class="flex">
                            <el-input
                                v-model="row.value_list[0]"
                                size="small"/>
                            <div class="si-filters-range-divider">&#8212;</div>
                            <el-input
                                v-model="row.value_list[1]"
                                size="small"/>
                        </div>
                        <div v-else>{{value_type(row.field_data, row.logics)}}</div>
                    </div>
                </div>
            </div>

            <div class="prb-filters-row">
                <el-button type="text" class="prb-filters-add" @click="open_fields_window">
                    <i class="el-icon-circle-plus-outline"></i>condition
                </el-button>
            </div>
        </div>
        <ReportFields 
            :open="fields_window_open" 
            title="Add condition"
            @closed="fields_window_open = false"
            @selected-fields="handle_selected_fields"
            :selected="selected_fields"
            :fields="fields"
            call_type="filter"  />
    </div>
</template>

<script>
import DatePicker from './DatePicker'
import ReportFields from './ReportFields'
export default {
    props: {
        fields: {
            required: true
        },
        loading: {
            default: false
        }
    },
    components: { ReportFields, DatePicker },

    data(){
        return {
            filters: [],
            fields_window_open: false,
            selected_fields: [],
            is_clearing: false,
        }
    },

    methods: {
        load_filters(filters){
            this.filters = filters
            this.filters.forEach(f => { this.change_row_value(f) })
        },

        value_type(field_data, logics_operator){
            if (!field_data) return '';
            if (!logics_operator) return '';

            if (['IS EXIST','IS NOT EXIST'].includes(logics_operator))
                return '';

            if (field_data.report_date) {
                return 'report_date_input';
            }

            if (field_data.type === 'datetime') {
                if (logics_operator === 'BETWEEN')
                    return 'range_date_input';
                else
                    return 'date_input';
            }

            if ('options' in field_data && field_data.options) {
                if (['IN','NOT IN'].includes(logics_operator))
                    return 'select_multi_input';
                else
                    return 'select_input';
            }

            if (['string','float','int'].includes(field_data.type)) {
                if (['IN','NOT IN'].includes(logics_operator))
                    return 'multi_input';
                else if (logics_operator === 'BETWEEN') {
                    return 'range_input';
                }
                else
                    return 'input';
            }

            return '';
        },

        set_date_text(row, text_array){
            this.$set(row, 'date_text', text_array[0])
            this.$set(row, 'date_shortcut', text_array[1])
        },

        open_fields_window(){
            this.selected_fields = []
            this.filters.forEach(f => {
                this.selected_fields.push(f.field)
            })
            this.fields_window_open = true
        },

        handle_selected_fields(fields){
            this.fields_window_open = false

            this.filters = this.filters.filter(f => fields.includes(f.field))
            fields.filter(f => !this.filters.some(e => e.field === f)).forEach(f => {
                let field_data = this.fields.find(fd => fd.id === f)
                let filter_row = {
                    apply      : true,
                    rule       : 'AND',
                    field      : field_data.id,
                    field_data : field_data,
                    value      : undefined,
                    value_list : [],
                    logics     : field_data.operands[0]
                }
                this.filters.push(filter_row)
                this.change_row_value(filter_row)
            })
        },

        change_row_logics(row){
            if ([ 'IN', 'NOT IN', 'BETWEEN'].includes(row.logics)){
                row.value = undefined;
            }
            else if ([ 'IS EXIST', 'IS NOT EXIST'].includes(row.logics)) {
                row.value = undefined;
                row.value_list = [];
            }
            else {
                row.value_list = [];
            }
        },

        change_row_value(row){
            if (['client_id', 'portfolio_id'].includes(row.field)) {
                let clientid_filter = this.filters.find(e => e.field === 'client_id')
                if (clientid_filter) {
                    let portfolioid_filter = this.filters.find(e => e.field === 'portfolio_id')
                    if (portfolioid_filter) {
                        if (clientid_filter.value) {
                            this.$store.dispatch('portfolio/getList', {client_id: clientid_filter.value}).then((response) => {
                                let options = []
                                response.forEach(item => {
                                    options.push({ value: item._id, label: item.name })
                                })
                                portfolioid_filter.field_data.options = options
                                if (portfolioid_filter.value) {
                                    if (!options.some(e => e.value === portfolioid_filter.value))
                                        portfolioid_filter.value = undefined;
                                }
                                else if (portfolioid_filter.value_list) {
                                    portfolioid_filter.value_list = portfolioid_filter.value_list.filter(v => options.some(e => e._id === v))
                                }
                            })
                        }
                        else {
                            this.$set(portfolioid_filter, 'value', undefined)
                            this.$set(portfolioid_filter, 'value_list', [])
                            this.$set(portfolioid_filter.field_data, 'options', [])
                        }
                    }
                }
            }
        },
        clear_data(){
            this.is_clearing = true
            this.filters = []
            this.fields_window_open = false
            this.selected_fields = []
            this.$nextTick(() => (this.is_clearing = false))
        }
    },

    watch: {
        filters: {
            deep: true,
            immediate: true,
            handler(val){
                if(!this.is_clearing) this.$emit('update', val)
            }
        },

    }
}
</script>
