<template>
    <div class="prb-settings">
        <div class="prb-settings-title">Columns</div>
        <div class="prb-settings-body" v-loading="loading">
            <div  class="prb-settings-wrapper">
                <draggable v-model="columns" draggable=".prb-settings-item" @change="change_order" class="prb-settings-wrapper">
                    <div 
                        v-for="(col, index) in columns" 
                        class="prb-settings-item">
                        <div class="prb-settings-item_sum" v-if="['float','int'].includes(col.field_data.type)">
                            <el-button type="text" class="prb-settings-item_sum-btn" v-bind:class="{ active: col.sum }" @click="set_col_sum(col)">
                                <svg-icon icon-class="sum" />
                            </el-button>
                        </div>
                        <div class="prb-settings-item_title">{{col.field_data.title}}</div>
                        <div class="prb-settings-item_close">
                            <el-button type="text" class="prb-settings-item_sum-btn" @click="columns.splice(index, 1);">
                                <i class="el-icon-close"></i>
                            </el-button>
                        </div>
                    </div>
                </draggable>
                <el-button type="text" class="prb-settings-add" @click="open_fields_window">
                    <i class="el-icon-circle-plus-outline"></i>
                </el-button>
            </div>
        </div>

        <ReportFields 
            :open="fields_window_open" 
            title="Add column"
            @closed="fields_window_open = false"
            @selected-fields="handle_selected_fields"
            :selected="selected_fields"
            :fields="fields"
            call_type="columns"  />
    </div>
</template>

<script>
import ReportFields from './ReportFields'
import draggable from "vuedraggable";
import {firstBy} from "thenby";
export default {
    props: {
        fields: {
            required: true
        },
        loading: {
            default: false
        }
    },
    components: { draggable, ReportFields },

    data(){
        return {
            columns: [],
            fields_window_open: false,
            selected_fields: [],
            is_clearing: false,
        }
    },

    methods: {
        load_columns(columns){
            this.$nextTick(() => {
                columns.forEach(c => {
                    c.field_data = this.fields.find(fd => fd.id === c.field)
                })
                columns.sort(firstBy(function (v) { return v.field_data.order; }, {direction:"asd"}))
                this.columns = columns
            });
        },
        open_fields_window(){
            this.selected_fields = []
            this.columns.forEach(f => {
                this.selected_fields.push(f.field)
            })
            this.fields_window_open = true
        },
        handle_selected_fields(fields){
            this.fields_window_open = false
            this.columns = this.columns.filter(el => {
                return fields.some((f) => {
                    return f.id === el.field
                });
            })
            fields.forEach(f => {
                let exist = this.columns.find(item => item.field == f)
                if (!exist) {
                    let field_data = this.fields.find(fd => fd.id === f)
                    this.columns.push({
                        field      : field_data.id,
                        field_data : field_data,
                        sum        : false,
                        order      : 0,
                    })
                }
            })
            this.change_order()
        },
        set_col_sum(col){
            this.$set(col, 'sum', !!!col.sum)
        },
        change_order(){
            console.log('change order')
            let order = 0
            this.columns.forEach(c => {
                order++;
                c.order = order
            })
        },
        clear_data(){
            this.is_clearing = true
            this.columns = []
            this.fields_window_open = false
            this.selected_fields = []
            this.$nextTick(() => (this.is_clearing = false))
        }
    },

    watch: {
        columns: {
            deep: true,
            handler(val){
                if(!this.is_clearing) this.$emit('update', val)
            }
        },

    }
}
</script>
