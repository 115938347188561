<template>
    <div v-if="tpl">
        <!-- <json-viewer :value="tpl.filters" :expand-depth=2></json-viewer> -->
        <div class="prb-header">
            <EditUserReport
                v-model="tpl"
                @change="report_changed_event" />
            <div>
                <el-button type="primary" @click="get_report">Get report</el-button>
            </div>
        </div>

        <ReportFilter
            ref="ReportFilter"
            :fields="fields"
            :loading="report_settings_loading"
            @update="update_tpl_filters" />

        <ReportColumns
            ref="ReportColumns"
            :fields="fields"
            :loading="report_settings_loading"
            @update="update_tpl_columns" />

        <ReportGroupby
            ref="ReportGroupby"
            :fields="fields"
            :loading="report_settings_loading"
            @update="update_tpl_groupby" />

        <ReportSortby
            ref="ReportSortby"
            :fields="fields"
            :loading="report_settings_loading"
            @update="update_tpl_sortby" />

        <div class="prb-report-buttons">
            <div class="spacer"></div>
            <el-button size="small" type="primary" plain @click="save_new_report_open = true">Save as new report</el-button>
            <el-button size="small" type="primary" plain :disabled="tpl.system" @click="report_change_event">Save report changes</el-button>
            <el-button size="small" type="danger" :disabled="tpl.system" @click="report_delete_event">Delete report</el-button>
        </div>

        <NewUserReport 
            :open="save_new_report_open" 
            @closed="save_new_report_open = false"
            @saved="new_report_saved_event"
            :tpl="tpl" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { date_shortcut } from '@/utils'
import {firstBy} from "thenby";
import ReportFilter from './ReportComponents/ReportFilter'
import ReportColumns from './ReportComponents/ReportColumns'
import ReportGroupby from './ReportComponents/ReportGroupby'
import ReportSortby from './ReportComponents/ReportSortby'
import NewUserReport from './ReportComponents/NewUserReport'
import EditUserReport from './ReportComponents/EditUserReport'

import PnlFields from './Reports/PlReport/Fields.json'

export default {
    components: { 
        ReportFilter, 
        ReportColumns, 
        ReportGroupby, 
        ReportSortby, 
        NewUserReport, 
        EditUserReport, 
    },

    data(){
        return {
            tpl : {
                filters : [],
                columns : [],
                groupby : [],
                sortby  : [],
            },
            save_new_report_open : false,
            report_errors        : false,
            report_settings_loading: true,
        }
    },

    computed: {
        ...mapState({
            currency_list  : state => state.currency.list,
            current_report : state => state.userReports.current,
            clients_list   : state => state.clients.list
        }),

        fields(){
            if (!this.tpl) 
                return [];
            if (this.tpl.category === 'pnl')
                return PnlFields;
        }
    },

    methods: {
        load_settings(tpl){
            this.clear_data()
            this.tpl = tpl
            this.tpl.filters = JSON.parse(JSON.stringify(this.tpl.filters))

            this.report_settings_loading = true
            this.process_fields().then(() => {
                this.add_obligatory_fields()

                this.$refs.ReportFilter.load_filters(this.tpl.filters)
                this.$refs.ReportColumns.load_columns(this.tpl.columns)
                this.$refs.ReportGroupby.load_groupby(this.tpl.groupby)
                this.$refs.ReportSortby.load_sortby(this.tpl.sortby)

                this.report_settings_loading = false
            })
        },

        set_report_etag(etag){
            this.$set(this.tpl, '_etag', etag)
        },

        async process_fields(){
            // let text = ''

            return await Promise.all(
                this.fields.map(async (field) => {
                    // if (field.parent_id)
                    // text += "{ '" + [field.id] + "' : {'type': '" + field.type+ "' }}\n";
                    if (!field.operands)
                        field.operands = this.get_field_operands(field);

                    if (field.id === 'client_id') {
                        await this.$store.dispatch('clients/getClientsList').then((response) => {
                            let options = []
                            response.forEach(item => {
                                options.push({ value: item._id, label: item.first_name + ' ' + item.last_name })
                            })
                            field.options = options
                        })
                    }
                    else if (['currency','icur','portfolio_cur'].includes(field.id)) {
                        await this.$store.dispatch('currency/getList').then(() => {
                            let options = []
                            this.currency_list.forEach(item => {
                                options.push({ value: item.code, label: item.code })
                            })
                            field.options = options
                        })
                    }
                    else if (field.id === 'position_type') {
                        field.options = [
                            { value: 'long', label: 'long' },
                            { value: 'short', label: 'short' },
                            { value: 'flat', label: 'flat' },
                        ]
                    }
                    else if (field.id === 'asset_class') {
                        field.options = [
                            { value: 'cash', label: 'Currency' },
                            { value: 'bond', label: 'Fixed Income' },
                            { value: 'equity', label: 'Equity' },
                            { value: 'future', label: 'Future' },
                            { value: 'option', label: 'Option' },
                        ]
                    }
                    else if (field.id === 'call_put') {
                        field.options = [
                            { value: 'CALL', label: 'CALL' },
                            { value: 'PUT', label: 'PUT' },
                        ]
                    }
                    else if (field.id === 'rating') {
                        field.options = [
                            { value: '21', label: 'AAA' },
                            { value: '20', label: 'AA+' },
                            { value: '19', label: 'AA' },
                            { value: '18', label: 'AA-' },
                            { value: '17', label: 'A+' },
                            { value: '16', label: 'A' },
                            { value: '15', label: 'A-' },
                            { value: '14', label: 'BBB+' },
                            { value: '13', label: 'BBB' },
                            { value: '12', label: 'BBB-' },
                            { value: '11', label: 'BB+' },
                            { value: '10', label: 'BB' },
                            { value: '9', label: 'BB-' },
                            { value: '8', label: 'B+' },
                            { value: '7', label: 'B' },
                            { value: '6', label: 'B-' },
                            { value: '5', label: 'CCC+' },
                            { value: '4', label: 'CCC' },
                            { value: '3', label: 'CCC-' },
                            { value: '2', label: 'CC' },
                            { value: '1', label: 'C' },
                            { value: '0', label: 'D' },
                        ]
                    }
                })
            )
            // console.log(text)
        },

        add_obligatory_fields(){
            this.fields.forEach(field => {
                if (field.parent_id && field.obligatory) {
                    let field_in_filters = this.tpl.filters.find(x => x.field === field.id)
                    if (!field_in_filters) 
                        this.tpl.filters.push({
                            apply      : true,
                            rule       : 'AND',
                            field      : field.id,
                            field_data : field,
                            value      : undefined,
                            value_list : [],
                            logics     : field.operands[0]
                        })
                }
            })

            this.tpl.filters.forEach(filter => {
                filter.field_data = this.fields.find(x => x.id === filter.field)
                if (!filter.field_data.obligatory) filter.field_data.obligatory = false;
                if (filter.field_data.type === 'datetime' && filter.logics && filter.date_shortcut){
                    let date_data = date_shortcut(filter.date_shortcut)
                    this.$set(filter, 'value', this.$moment(date_data.date).format('YYYY-MM-DD'))
                    this.$set(filter, 'date_text', date_data.text)
                }
            })

            this.tpl.filters.sort(firstBy(function (v) { return v.field_data.obligatory; }, {direction:"desc"}))
        },

        get_field_operands(field){
            let list = []
            if (field.type === 'string')
                list = [ "EQUAL", "NOT EQUAL", "IS EXIST", "IS NOT EXIST", "IN",  "NOT IN"];
            else if (field.type === 'datetime')
                list = [ "EQUAL", "NOT EQUAL", "LESS", "LESS OR EQUAL", "GREATER", "GREATER OR EQUAL", "BETWEEN", "IS EXIST", "IS NOT EXIST", ];
            else if (field.type === 'float' || field.type === 'int')
                list = [ "EQUAL",  "NOT EQUAL", "LESS", "LESS OR EQUAL", "GREATER", "GREATER OR EQUAL", "BETWEEN",  "IN",  "NOT IN", "IS EXIST", "IS NOT EXIST",];
            else if (field.type === 'boolean')
                list = [ "TRUE",  "FALSE", "IS EMPTY", ];
            return list
        },

        clear_data(){
            this.tpl = {}
            this.$set(this.tpl, 'filters', [])
            this.$set(this.tpl, 'columns', [])
            this.$set(this.tpl, 'groupby', [])
            this.$set(this.tpl, 'sortby', [])
            this.$refs.ReportColumns.clear_data()
            this.$refs.ReportFilter.clear_data()
            this.$refs.ReportGroupby.clear_data()
            this.$refs.ReportSortby.clear_data()
        },

        update_tpl_filters(filters){
            this.$set(this.tpl, 'filters', filters)
            this.$emit('change-path', this.tpl)
        },

        update_tpl_columns(columns){
            this.$set(this.tpl, 'columns', columns)
            this.$emit('change-path', this.tpl)
        },

        update_tpl_groupby(groupby){
            this.$set(this.tpl, 'groupby', groupby)
            this.$emit('change-path', this.tpl)
        },

        update_tpl_sortby(sortby){
            this.$set(this.tpl, 'sortby', sortby)
            this.$emit('change-path', this.tpl)
        },

        new_report_saved_event(){
            this.$emit('report-added')
        },

        report_changed_event(formdata){
            console.log('report cha ev')
            this.tpl._etag = this.current_report._etag
            this.tpl.title = formdata.title
            if(formdata.description) this.tpl.description = formdata.description
            this.$emit('report-updated')
            this.$emit('change-path', this.tpl)
        },

        report_delete_event(){
            this.$confirm('Report will be deleted.', 'DELETE REPORT', {
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
                type: 'warning',
                confirmButtonClass: 'red-confirm-btn'
            }).then(() => {
                this.$store.dispatch('userReports/deleteItem', {id: this.tpl.id, etag: this.tpl._etag}).then(() => {
                    this.$emit('report-deleted')
                });
            }).catch(() => {});
        },

        report_change_event(){
            let formdata = {
                _id: this.tpl.id,
                _etag: this.tpl._etag,
            }
            formdata.content = {
                columns: JSON.parse(JSON.stringify(this.tpl.columns)),
                filters: JSON.parse(JSON.stringify(this.tpl.filters)),
                groupby: JSON.parse(JSON.stringify(this.tpl.groupby)),
                sortby: JSON.parse(JSON.stringify(this.tpl.sortby)),
            }

            formdata.content.columns.forEach(f => {
                delete f.field_data;
            })
            formdata.content.filters.forEach(f => {
                delete f.field_data;
                if (f.field === "client_id")
                    f.value = null;
                else if (f.date_shortcut)
                    f.value = null;
            })
            formdata.content.groupby.forEach(f => {
                delete f.field_data;
            })
            formdata.content.sortby.forEach(f => {
                delete f.field_data;
            })

            this.$store.dispatch('userReports/updateItem', formdata).then(() => {
                this.tpl._etag = this.current_report._etag
                this.$emit('report-updated')
            });
        },

        error_notification(){
            this.$message({
                type: 'error',
                message: 'Report cannot be created. Please, check report settings.'
            })
        },

        get_report(){
            this.report_errors = false;
            this.fields.forEach(field => {
                if (field.parent_id && field.obligatory) {
                    let field_in_filters = this.tpl.filters.find(x => x.field === field.id)
                    if (!field_in_filters) 
                        this.report_errors = true;
                    else if (!field_in_filters.value && !field_in_filters.value_list.length)
                        this.report_errors = true;
                }
            })
            if (this.report_errors){
                this.error_notification();
                return
            }

            this.$emit('report-run', this.tpl)
        }
    },
}
</script>
