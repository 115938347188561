<template>
    <div class="prb-title-holder">
        <div>
            <div class="prb-title">
                <div class="prb-title-fav">
                    <el-button type="text" v-if="!is_fav" @click="set_favorite"><svg-icon icon-class="star" /></el-button>
                    <el-button type="text" v-else @click="unset_favorite"><svg-icon icon-class="star_fill" /></el-button>
                </div>
                <div class="prb-title-text">{{viewdata.title}}</div>
                <div v-if="!viewdata.system"  class="prb-edit_btn">
                    <el-button type="text" @click="editmode_event"><svg-icon icon-class="edit" /></el-button>
                </div>
            </div>
            <div class="prb-description">
                {{viewdata.description}}
            </div>
        </div>
        <el-dialog
            :visible.sync="editmode"
            width="500px"
            append-to-body
            modal-append-to-body
            :close-on-click-modal="false"
            :destroy-on-close="true">

            <template slot="title"><div class="tl-dialog-title">Edit Report Name</div></template>

            <el-form
                label-position="top"
                label-width="140px"
                :model="formdata"
                size="small"
                :rules="form_rules"
                autocomplete="off"
                class="tl-form"
                ref="UserReportEditor">

                <el-form-item label="Name" prop="title">
                    <el-input v-model="formdata.title" />
                </el-form-item>

                <el-form-item label="Description" prop="description">
                    <el-input v-model="formdata.description" type="textarea" autosize />
                </el-form-item>

            </el-form>

            <div class="tl-form-buttons">
                <div class="spacer" />
                <el-button @click="editmode = false">Cancel</el-button>
                <el-button type="primary" @click="save_event">Save</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
    props: ['value'],

    data: function () {
        return {
            viewdata : this.value,
            editmode : false,
            formdata : {},
            is_fav: false,
            form_rules : {
                title: [ { required: true, message: 'this field is required', trigger: 'blur' }, ],
            },
        }
    },

    computed: {
      ...mapState({
          user_reports_favorites: state => state.userReportsFavorites.list
      })
    },

    methods: {
        set_favorite(){
            this.$store.dispatch('userReportsFavorites/addFavorite', {item: this.value})
        },
        unset_favorite(){
            this.$store.dispatch('userReportsFavorites/deleteFavorite', {item: this.value})
        },

        editmode_event(){
            this.editmode = true;
            this.formdata = Object.assign({}, JSON.parse(JSON.stringify(this.viewdata)));
        },

        save_event(){
            if (this.viewdata.title !== this.formdata.title || this.viewdata.description !== this.formdata.description) {
                this.$store.dispatch('userReports/updateItem', {
                    title: this.formdata.title,
                    description: this.formdata.description,
                    _etag: this.formdata._etag,
                    _id: this.formdata.id,
                }).then(() => {
                    this.$emit('change', this.formdata);
                    this.editmode = false;
                });
            }
            else {
                this.editmode = false;
            }
        }
    },

    watch: {
        user_reports_favorites(val){
            this.is_fav = this.user_reports_favorites.some((report) => report._id === this.value.id)
        },
        value(){
            this.viewdata = this.value
            this.editmode = false
            this.is_fav = this.user_reports_favorites.some((report) => report._id === this.value.id)
        }
    }
}
</script>
